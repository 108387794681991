export enum ReferralColumn {
  updated_at = 'Updated At',
  created_at = 'Created At',
  part_number = 'Part Number',
  part_description = 'Part Description',
  request_type = 'Request Type',
  file_link = 'File Link',
  plant_code = 'Plant Code',
  date_due = 'Date Due',
  date_requested = 'Date Requested',
  release_date = 'Release Date',
  entry_number = 'Entry Number',
  uuid = 'UUID',
}
