import { PlusIcon } from '@heroicons/react/outline';
import PrimaryButton from '../../buttons/PrimaryButton';
import {
  BrokerReferralFields,
  BrokerReferralPartsFields,
} from '../BrokerReferralForm';
import { PartsTable } from './PartsTable';
import TextAreaField from './TextAreaField';
import TextField from './TextField';
import { RequestTypeSelect } from './RequestTypeSelect';
import { useFormikContext } from 'formik';
import { useEffect, useState, useMemo } from 'react';
import { ClipboardCopyIcon } from '@heroicons/react/solid';
import OutlineButton from '../../buttons/OutlineButton';
import BulkAddField from './BulkAddField';

const Parts = () => {
  const { values, setFieldValue, errors, touched, validateForm } =
    useFormikContext<typeof BrokerReferralFields>();
  const [isBulkAdd, setIsBulkAdd] = useState(false);

  const handleAddPartRequest = async () => {
    const selectedRequestTypes =
      (values[BrokerReferralPartsFields.request_type] as any as string[]) || [];
    const partNumber = values[BrokerReferralPartsFields.part_number];
    const partDescription = values[BrokerReferralPartsFields.part_description];

    const newParts = selectedRequestTypes?.map((requestType) => ({
      request_type: requestType,
      part_number: partNumber,
      part_description: partDescription,
    }));

    setFieldValue('parts', [...newParts, ...(values.parts ?? [])], true);

    try {
      setFieldValue(BrokerReferralPartsFields.part_number, '', true);
      setFieldValue(BrokerReferralPartsFields.part_description, '', true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleBulkAdd = () => {
    setIsBulkAdd(!isBulkAdd);
  };

  const handleBulkAddSubmit = () => {
    const bulkText = values[BrokerReferralPartsFields.bulk_text] || '';
    const rows = bulkText.split('\n');
    const newParts = rows
      .map((row) => {
        const [request_type, part_number, part_description] = row
          .replace(/\t/g, ',')
          .split(',');
        return { request_type, part_number, part_description };
      })
      .filter(
        (part) => part.request_type && part.part_number && part.part_description
      );

    setFieldValue('parts', [...newParts, ...(values.parts ?? [])], true);
    setFieldValue(BrokerReferralPartsFields.bulk_text, '', true);
  };

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.parts]);

  const isValidBulkCSV = useMemo(() => {
    if (!isBulkAdd) return false;
    const bulkText = values[BrokerReferralPartsFields.bulk_text] || '';
    const rows = bulkText.split('\n').filter((row) => row.trim() !== '');
    return (
      rows.length > 0 &&
      rows.every((row) => row.replace(/\t/g, ',').split(',').length === 3)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBulkAdd, values[BrokerReferralPartsFields.bulk_text]]);

  const isAddDisabled = isBulkAdd
    ? !isValidBulkCSV
    : !values[BrokerReferralPartsFields.request_type]?.length ||
      !values[BrokerReferralPartsFields.part_number] ||
      !values[BrokerReferralPartsFields.part_description];

  return (
    <div className="shadow-sm border border-gray-300 p-5 w-full col-span-2 rounded-md bg-white/50 flex flex-col gap-6">
      <div className="w-full flex justify-between items-center">
        <h3 className="text-base leading-6 font-medium text-gray-900">
          Part Requests
        </h3>
        <div className="flex gap-2">
          <OutlineButton type="button" onClick={handleBulkAdd}>
            <ClipboardCopyIcon className="h-5 w-5 mr-2" />
            {isBulkAdd ? 'Add Single' : 'Add Multiple'}
          </OutlineButton>
          <PrimaryButton
            type="button"
            onClick={isBulkAdd ? handleBulkAddSubmit : handleAddPartRequest}
            disabled={isAddDisabled}
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            {isBulkAdd ? 'Add Bulk Parts' : 'Add Part Request'}
          </PrimaryButton>
        </div>
      </div>
      {isBulkAdd ? (
        <BulkAddField
          name={BrokerReferralPartsFields.bulk_text}
          label="Bulk Add Parts"
          classes="col-span-2"
        />
      ) : (
        <div className="gap-3 flex-col grid grid-cols-1 sm:grid-cols-2">
          <RequestTypeSelect
            name={BrokerReferralPartsFields.request_type}
            label="Request Type"
          />
          <TextField
            name={BrokerReferralPartsFields.part_number}
            label="Part Number"
            classes="col-span-1"
          />
          <TextAreaField
            name={BrokerReferralPartsFields.part_description}
            label="Part Description"
            classes="col-span-2"
          />
        </div>
      )}
      <PartsTable />
      {errors['parts'] && touched['parts'] ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {errors['parts']}
        </p>
      ) : null}
    </div>
  );
};

export default Parts;
