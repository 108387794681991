import { Form, Formik } from 'formik';
import TextField from './form/TextField';
import DateField from './form/DateField';
import UploadFile from './form/UploadFile';
import SubmitButton from './SubmitButton';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { IBrokerReferralMutation } from '../../interfaces/IBrokerReferralMutation';
import axios from 'axios';
import { useNotifications } from '../notifications/useNotifications';
import Http from '../../services/http';
import { useNavigate } from 'react-router-dom';
import Parts from './form/Parts';

export enum BrokerReferralFields {
  entry_number = 'entry_number',
  release_date = 'release_date',
  contact_name = 'contact_name',
  email = 'email',
  date_requested = 'date_requested',
  date_due = 'date_due',
  plant_code = 'plant_code',
  file_link = 'file_link',
  parts = 'parts',
  request_type = 'request_type',
  part_number = 'part_number',
  part_description = 'part_description',
  uuid = 'uuid',
  bulk_text = 'bulk_text',
}

export enum BrokerReferralPartsFields {
  request_type = 'request_type',
  part_number = 'part_number',
  part_description = 'part_description',
  bulk_text = 'bulk_text',
}

const validationSchema = Yup.object({
  entry_number: Yup.string().required('Required'),
  release_date: Yup.date().required('Required'),
  date_due: Yup.date().required('Required'),
  plant_code: Yup.string().required('Required'),
  file_link: Yup.array().optional(),
  parts: Yup.array()
    .min(1, 'At least one part is required')
    .required()
    .of(
      Yup.object().shape({
        part_number: Yup.string().required('Part number is required'),
        part_description: Yup.string().required('Part description is required'),
        request_type: Yup.string().required('Request type is required'),
      })
    ),
});

export const BrokerReferralForm = () => {
  const { pushNotification } = useNotifications();
  const navigate = useNavigate();

  const mutation = useMutation<unknown, unknown, IBrokerReferralMutation>(
    (data) => {
      const formData = new FormData();

      Object.keys(data).forEach((key) => {
        if (key === 'files') {
          (data as any)[key].forEach((file: File) => {
            formData.append('files', file);
          });
        } else if (key === 'parts') {
          formData.append('parts', JSON.stringify(data[key]));
        } else {
          formData.append(key, data[key]);
        }
      });

      return axios.post(
        `${process.env.REACT_APP_API}/broker-referral`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${Http.accessToken}`,
          },
        }
      );
    },
    {
      onSuccess: (response: any) => {
        pushNotification({
          heading: 'Referral Submitted',
          content: 'Your referral has been submitted successfully.',
        });

        navigate(`/referrals/${response.data.referral_id}`);
      },
    }
  );

  return (
    <Formik
      initialValues={{
        entry_number: '',
        release_date: undefined,
        contact_name: '',
        email: '',
        date_due: undefined,
        plant_code: '',
        request_type: '',
        part_number: '',
        part_description: '',
        file_link: [],
        parts: [],
      }}
      validationSchema={validationSchema}
      validateOnChange
      onSubmit={(values, { resetForm }) => {
        mutation.mutate(values);

        resetForm();
      }}
    >
      {({ isValid, isSubmitting, setFieldValue, errors }) => {
        return (
          <Form>
            <div className="grid grid-cols-2 gap-4 sm:gap-6">
              <TextField
                name={BrokerReferralFields.entry_number}
                label="Entry Number"
              />
              <DateField
                name={BrokerReferralFields.release_date}
                label="Release Date"
              />
              <DateField
                name={BrokerReferralFields.date_due}
                label="Date Due"
              />
              <TextField
                name={BrokerReferralFields.plant_code}
                label="Plant Code"
              />
              <Parts />
              <UploadFile name="files" label="File Upload" />
            </div>
            <div className="w-full flex justify-end mt-6">
              <SubmitButton isValid={isValid} isLoading={mutation.isLoading} />
              {/* <button
                type="button"
                onClick={() => {
                  setFieldValue(
                    BrokerReferralFields.entry_number,
                    'Sample Entry Number'
                  );
                  setFieldValue(
                    BrokerReferralFields.release_date,
                    new Date().toISOString().slice(0, 10)
                  );
                  setFieldValue(
                    BrokerReferralFields.date_due,
                    new Date().toISOString().slice(0, 10)
                  );
                  setFieldValue(
                    BrokerReferralFields.plant_code,
                    'Sample Plant Code'
                  );
                  setFieldValue(BrokerReferralFields.parts, [
                    {
                      request_type: 'Sample Request Type',
                      part_number: 'Sample Part Number',
                      part_description: 'Sample Part Description',
                    },
                  ]);
                }}
              >
                Fill Sample Data
              </button> */}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
